// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  padding: 0;
  font-family: "Courier New", Courierm monospace;
  background: linear-gradient(89deg, #FF5EDF 0%, #04C8DE 100%);
}

button {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,UAAU;EACV,8CAA8C;EAC9C,4DAA4D;AAC9D;;AAEA;EACE,eAAe;AACjB","sourcesContent":["body {\n  margin: 0;\n  padding: 0;\n  font-family: \"Courier New\", Courierm monospace;\n  background: linear-gradient(89deg, #FF5EDF 0%, #04C8DE 100%);\n}\n\nbutton {\n  cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
