// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form {
  width: 800px;
  background: #222;
  background-size:40px 60px;
}

button {
  width: 30%;
  cursor: pointer; /* Changes the cursor to indicate the button is clickable */
  border: none; /* Removes the default border */
}

`, "",{"version":3,"sources":["webpack://./src/components/ImageLinkForm/ImageLinkForm.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,gBAAgB;EAChB,yBAAyB;AAC3B;;AAEA;EACE,UAAU;EACV,eAAe,EAAE,2DAA2D;EAC5E,YAAY,EAAE,+BAA+B;AAC/C","sourcesContent":[".form {\n  width: 800px;\n  background: #222;\n  background-size:40px 60px;\n}\n\nbutton {\n  width: 30%;\n  cursor: pointer; /* Changes the cursor to indicate the button is clickable */\n  border: none; /* Removes the default border */\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
