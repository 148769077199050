// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bounding-box {
	position: absolute;
	box-shadow: 0 0 0 3px #149df2 inset;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/FaceRecognition/FaceRecognition.css"],"names":[],"mappings":"AAAA;CACC,kBAAkB;CAClB,mCAAmC;CACnC,aAAa;CACb,eAAe;CACf,uBAAuB;CACvB,eAAe;AAChB","sourcesContent":[".bounding-box {\n\tposition: absolute;\n\tbox-shadow: 0 0 0 3px #149df2 inset;\n\tdisplay: flex;\n\tflex-wrap: wrap;\n\tjustify-content: center;\n\tcursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
