import React from 'react';
import './FaceRecognition.css';

const FaceRecognition = ({ imageUrl, box }) => {
  return (
    <div className='center ma'>
      <div className='absolute mt2'>
        <img id='inputimage' alt='' src={imageUrl} width='500px' height='auto'/>
        {
          box.map((bbox, index) => (
            <div key={index} className='bounding-box' style={{
              top: bbox.topRow, 
              right: bbox.rightCol, 
              bottom: bbox.bottomRow, 
              left: bbox.leftCol
            }}></div>
          ))
        }
      </div>
    </div>
  );
}

export default FaceRecognition;
