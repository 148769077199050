import React, { Component } from 'react';
import ParticlesBg from 'particles-bg'
import Navigation from './components/Navigation/Navigation';
import FaceRecognition from './components/FaceRecognition/FaceRecognition';
import ImageLinkForm from './components/ImageLinkForm/ImageLinkForm';
import Signin from './components/Signin/Signin';
import Register from './components/Register/Register';
import Rank from './components/Rank/Rank';
import './App.css';

const initialState = {
  input: '',
  imageUrl: '',
  box: [],
  route: 'signin',
  isSignedIn: false,
  user: {
    id: '',
    name: '',
    email: '',
    entries: 0,
    joined: ''
  }
}

class App extends Component{
  constructor(){
    super()
    this.state = initialState;
  }

  loadUser = (data) => {
    this.setState({user: {
      id: data.id,
      name: data.name,
      email: data.email,
      entries: data.entries,
      joined: data.joined
    }})
  }

  calculateFaceLocation = (data) => {
      const faceData = data.outputs[0].data.regions.map(region => {
        const clarifaiFace = region.region_info.bounding_box;
        const image = document.getElementById('inputimage');
        const width = Number(image.width);
        const height = Number(image.height);
        return {
          leftCol: clarifaiFace.left_col * width,
          topRow: clarifaiFace.top_row * height,
          rightCol: width - (clarifaiFace.right_col * width),
          bottomRow: height - (clarifaiFace.bottom_row * height)
        };
      });
      return faceData;
  }

  displayFaceBox = (box) => {
    this.setState({box: box});
  }

  onInputChange = (event) => {
    this.setState({input: event.target.value});
  }

  onButtonSubmit = () => {
    this.setState({ imageUrl: this.state.input });
    fetch('https://shrouded-spire-63169-859cf162b02d.herokuapp.com/imageurl', {
        method: 'post',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
            input: this.state.input
        })
    })
    .then(response => response.json())
    .then(response => {
        if (response && response.outputs) {
            this.displayFaceBox(this.calculateFaceLocation(response));
            fetch('https://shrouded-spire-63169-859cf162b02d.herokuapp.com/image', {
                method: 'put',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    id: this.state.user.id
                })
            })
            .then(response => response.json())
            .then(count => {
                this.setState(Object.assign(this.state.user, { entries: count }))
            })
            .catch(console.log);
        }
    })
    .catch(err => console.log(err));
}


  onRouteChange = (route) => {
    if (route === 'signout') {
      this.setState(initialState)
    } else if ( route === 'home') {
      this.setState({isSignedIn: true})
    }
    this.setState({route: route}); 
  }

  render() {
    const { isSignedIn, imageUrl, route, box } = this.state;
    return (
      <div className="App">
        <div className="particles-container">
            <ParticlesBg color="#FFFFFF" num={200} type="cobweb" bg={true} />
          </div>
          <Navigation isSignedIn={isSignedIn} onRouteChange={this.onRouteChange} />
          { route === 'home'
            ? <div>
                <section className="intro-box">
                  <h1>
                    <span className="h1-part1">FACE </span>
                    <span className="h1-part2"> RECOGNITION</span>
                  </h1>
                  <p>The potenSDev brings cutting-edge technology straight to your screen,<br />
                   effortlessly detecting faces in your pictures.
                  </p>
                </section>

                <section className="intro-box">
                  <h2>How Our Face Recognition Works</h2>
                  <div className="steps">
                    <div className="step">
                      <h3>Step 1: Upload Your Image</h3>
                      <p>Simply provide the URL of your image or upload it directly from your device. Our platform supports a wide range of formats for your convenience.</p>
                    </div>
                    <div className="step">
                      <h3>Step 2: Detection in Action</h3>
                      <p>Our advanced algorithms spring into action, analyzing your image with precision to detect faces, no matter the background complexity.</p>
                    </div>
                  </div>
                </section>

                <section className="recognition-box">
                  <Rank
                    name={this.state.user.name}
                    entries={this.state.user.entries}
                  />
                  <ImageLinkForm
                    onInputChange={this.onInputChange}
                    onButtonSubmit={this.onButtonSubmit}
                  />
                  <FaceRecognition box={box} imageUrl={imageUrl} />
                </section>
              </div>
            : (
               route === 'signin'
               ? <Signin loadUser={this.loadUser} onRouteChange={this.onRouteChange}/>
               : <Register loadUser={this.loadUser} onRouteChange={this.onRouteChange}/>
              )
          }
      </div>
    );
  }
}

export default App;
